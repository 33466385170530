import ApiService from "./apiService.js";

class PingService extends ApiService {
  constructor(baseUrl) {
    super(baseUrl);

    this.url = "admin/ping/";
  }

  async ping() {
    return await this.fetch(this.url);
  }
}

export default PingService;
