class ApiService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.token = localStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  async fetch(url, options) {
    return await fetch(`https://lunarapi.stephandam.nl/${url}`, {
      ...options,
      headers: this.headers,
    })
      .catch((error) => {
        console.error(error);

        return { error: true, response: error };
      })
      .then((response) => {
        if (response.status === 401 && window.location.pathname !== "/login") {
          localStorage.removeItem("token");
          window.location.href = "/login";

          response = null;
        }

        if (response.error) {
          return response;
        }

        let data = response.json();

        return data;
      });
  }
}

export default ApiService;
