<script setup>
import Main from "@/layout/main.vue";
import Login from "@/layout/login.vue";

import PingService from "@/services/ping";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
const route = useRoute();

const pingService = new PingService();
const loaded = ref(false);
onMounted(async () => {
  await pingService.ping();
  loaded.value = true;
});
</script>

<template>
  <Login v-if="loaded && route.meta.layout">
    <router-view></router-view>
  </Login>
  <Main v-if="loaded && !route.meta.layout">
    <router-view></router-view>
  </Main>
</template>
