export default [
  {
    path: "/login",
    name: "login",
    meta: { title: "Login", layout: "login" },
    component: () => import("../views/login/index.vue"),
  },

  {
    path: "/",
    name: "dashboard",
    meta: { title: "Dashboard" },
    component: () => import("../views/dashboard.vue"),
  },
  {
    path: "/bookings",
    name: "bookings",
    meta: { title: "Bookings" },
    component: () => import("../views/bookings/index.vue"),
  },
  {
    path: "/bookings/view/:id",
    name: "BookingView",
    meta: { title: "Bookings view" },
    component: () => import("../views/bookings/view.vue"),
  },
  {
    path: "/rooms",
    name: "rooms",
    meta: { title: "Rooms" },
    component: () => import("../views/rooms/index.vue"),
  },
  {
    path: "/rooms/view/:id",
    name: "RoomView",
    meta: { title: "Room view" },
    component: () => import("../views/rooms/view.vue"),
  },
  {
    path: "/rooms/edit/:id",
    name: "RoomEdit",
    meta: { title: "Room edit" },
    component: () => import("../views/rooms/edit.vue"),
  },
  {
    path: "/rooms/create",
    name: "RoomCreate",
    meta: { title: "Room create" },
    component: () => import("../views/rooms/create.vue"),
  },
  {
    path: "/customers",
    name: "customers",
    meta: { title: "Customers" },
    component: () => import("../views/customers/index.vue"),
  },
];
