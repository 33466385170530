<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import FooterComponents from "@/components/ui/footer.vue";
import Navbar from "@/components/ui/navbar.vue";
import MenuComponents from "@/components/ui/sidebar.vue";

const store = useStore();

const handleOutsideClick = (event) => {
  if (store.state.isMobileSidebarActive && !this.$el.contains(event.target)) {
    // Close the mobile sidebar
    store.commit("toggleMobileSidebar");
  }
};

const isFixedWidth = computed(() => {
  return store.getters.isFixedWidth;
});
</script>

<template>
  <div
    class="pc-sidebar"
    @click="handleOutsideClick"
    :class="{
      'pc-sidebar-hide': store.state.isSidebarHidden,
      'mob-sidebar-active': store.state.isMobileSidebarActive,
    }"
  >
    <MenuComponents></MenuComponents>
    <div v-if="store.state.isMobileSidebarActive" class="pc-menu-overlay"></div>
  </div>
  <div class="pc-header" style="z-index: 1">
    <Navbar />
  </div>

  <div class="pc-container">
    <div class="pc-content" :class="{ container: isFixedWidth }">
      <!-- Start Content-->
      <div>
        <slot />
      </div>
    </div>
  </div>
  <div class="pc-footer">
    <FooterComponents />
  </div>
</template>
